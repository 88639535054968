/* eslint-disable no-magic-numbers */
function geodistance(lat1:number, lng1:number, lat2:number, lng2:number) {
  if (lat1 === lat2 && lng1 === lng2) {
    return 0;
  }

  const radlat1 = (Math.PI * lat1) / 180;
  const radlat2 = (Math.PI * lat2) / 180;

  const radtheta = (Math.PI * (lng1 - lng2)) / 180;

  let dist = Math.min(
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta), 1);

  dist = Math.acos(dist) * 180 / Math.PI;
  dist *= 60 * 1.1515 * 1.609344;

  return dist;
}

export { geodistance };
