/* eslint-disable max-statements */
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { Form, Field } from 'vee-validate';
import { VueQueryPlugin } from '@tanstack/vue-query';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

import VueGoogleMaps from '@fawmi/vue-google-maps';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import InlineSvg from 'vue-inline-svg';

import { VueperSlides, VueperSlide } from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';

import { setDefaultOptions } from 'date-fns';
import { es } from 'date-fns/locale';

import { i18n } from '../utils/i18n';

import ChatView from '../components/chat-view.vue';
import ChatsView from '../components/chats-view.vue';
import CategoryView from '../components/category-view.vue';
import HomeView from '../components/home-view.vue';
import MenuView from '../components/menu-view.vue';
import PartnerStartView from '../components/partner-start-view.vue';
import ProductView from '../components/product-view.vue';
import ProfileView from '../components/profile-view.vue';
import SearchView from '../components/search-view.vue';
import FaqView from '../components/faq-view.vue';
import ProductGrid from '../components/product-grid.vue';

import ProfileProductBookings from '../components/profile/profile-product-bookings.vue';
import ProfileBankAccount from '../components/profile/profile-bank-account.vue';
import ProfileProductsHistory from '../components/profile/profile-products-history.vue';
import ProfileProductHistoryDetails from '../components/profile/profile-product-history-details.vue';
import ProfileProducts from '../components/profile/profile-products.vue';
import ProfileAddresses from '../components/profile/profile-addresses.vue';
import ProfileAddressDetail from '../components/profile/profile-address-detail.vue';
import ProfileNewAddressForm from '../components/profile/profile-new-address-form.vue';
import ProfileEditAddressForm from '../components/profile/profile-edit-address-form.vue';
import ProfileAccount from '../components/profile/profile-account.vue';

import NewProductForm from '../components/new-product-form.vue';
import EditProductForm from '../components/edit-product-form.vue';
import NewProductRequestForm from '../components/new-product-request-form.vue';
import ProductRequestsGrid from '../components/product-requests-grid.vue';
import ProductRequestView from '../components/product-request-view.vue';

import PaymentSuccess from '../components/payment-success.vue';
import PaymentFailure from '../components/payment-failure.vue';

import BaseButton from '../components/base/base-button.vue';
import BaseFileInput from '../components/base/base-file-input.vue';
import BaseInput from '../components/base/base-input.vue';
import BaseCurrencyInput from '../components/base/base-currency-input.vue';
import BaseLabel from '../components/base/base-label.vue';
import BaseSelect from '../components/base/base-select.vue';
import BaseDragAndDrop from '../components/base/base-drag-and-drop.vue';
import BaseQrCode from '../components/base/base-qr-code.vue';
import BaseModal from '../components/base/base-modal.vue';
import BaseTip from '../components/base/base-tip.vue';
import BaseCounter from '../components/base/base-counter.vue';
import BaseGoogleAutocomplete from '../components/base/base-google-autocomplete.vue';
import BaseStars from '../components/base/base-stars.vue';
import BaseToast from '../components/base/base-toast.vue';
import SessionHandler from '../components/session-handler.vue';
import TheFooter from '../components/the-footer.vue';
import TheLayout from '../components/the-layout.vue';
import TheMenubar from '../components/the-menubar.vue';
import TheNavbar from '../components/the-navbar.vue';
import TheTitlebar from '../components/the-titlebar.vue';

import '../css/application.css';

setDefaultOptions({
  locale: es,
  weekStartsOn: 1,
});

document.addEventListener('DOMContentLoaded', () => {
  const app = createApp({
    components: {
      SessionHandler,
      HomeView,
      ProductView,
      ProductGrid,
      CategoryView,
      ChatsView,
      ChatView,
      NewProductForm,
      EditProductForm,
      NewProductRequestForm,
      SearchView,
      ProductRequestsGrid,
      ProductRequestView,
      PartnerStartView,
      ProfileView,
      ProfileProductBookings,
      ProfileBankAccount,
      ProfileProductsHistory,
      ProfileProductHistoryDetails,
      ProfileProducts,
      ProfileAddresses,
      ProfileNewAddressForm,
      ProfileEditAddressForm,
      ProfileAddressDetail,
      ProfileAccount,
      MenuView,
      FaqView,
      PaymentSuccess,
      PaymentFailure,
    },
  });

  const pinia = createPinia();

  app.component('VForm', Form);
  app.component('VField', Field);
  app.component('VueDatePicker', VueDatePicker);
  app.component('InlineSvg', InlineSvg);
  app.component('BaseButton', BaseButton);
  app.component('BaseFileInput', BaseFileInput);
  app.component('BaseInput', BaseInput);
  app.component('BaseCurrencyInput', BaseCurrencyInput);
  app.component('BaseLabel', BaseLabel);
  app.component('BaseSelect', BaseSelect);
  app.component('BaseDragAndDrop', BaseDragAndDrop);
  app.component('BaseQrCode', BaseQrCode);
  app.component('BaseModal', BaseModal);
  app.component('BaseTip', BaseTip);
  app.component('BaseCounter', BaseCounter);
  app.component('BaseGoogleAutocomplete', BaseGoogleAutocomplete);
  app.component('BaseStars', BaseStars);
  app.component('BaseToast', BaseToast);
  app.component('TheFooter', TheFooter);
  app.component('TheLayout', TheLayout);
  app.component('TheNavbar', TheNavbar);
  app.component('TheMenubar', TheMenubar);
  app.component('TheTitlebar', TheTitlebar);
  app.component('VueperSlides', VueperSlides);
  app.component('VueperSlide', VueperSlide);
  app.use(i18n);
  app.use(VueQueryPlugin);
  app.use(VueGoogleMaps, {
    load: {
      key: import.meta.env.VITE_GOOGLE_PLACES_API_KEY,
    },
  });
  app.use(pinia);
  pinia.use(piniaPluginPersistedstate);
  app.mount('#vue-app');

  return app;
});
