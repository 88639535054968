<script setup lang="ts">
</script>
<template>
  <div class="flex flex-col space-y-10 bg-z-turquoise-50 p-6 md:space-y-6 md:px-[12%]">
    <img
      src="@/assets/images/zirkular-logo.png"
      class="w-28"
      alt="Logo de Zirkular"
    >
    <div class="grid-cols-4 space-y-4 md:grid md:space-y-0">
      <div class="flex flex-col space-y-2 text-sm">
        <a href="/productos">Productos</a>
        <a href="/productos/crear">Sube tus productos</a>
        <!-- <a href="/necesidades_clientes/crear">¿No encuentras lo que buscas?</a>
        <a href="/necesidades_clientes">Necesidades del cliente</a> -->
      </div>
      <hr class="w-12 border-z-gray-500/40 md:hidden">
      <div class="flex flex-col space-y-2 text-sm">
        <a href="/preguntas_frecuentes">Preguntas frecuentes</a>
        <a href="/legal/terminos_y_condiciones">Términos y condiciones</a>
        <a href="/legal/privacidad">Política de privacidad</a>
      </div>
    </div>
    <div class="flex justify-center space-x-4 py-3 md:justify-start">
      <a
        href="mailto:contacto@zirkular.cl"
        rel="noopener"
        target="_blank"
        aria-label="Enviar un correo a contacto@zirkular.cl"
      >
        <img
          src="@/assets/icons/mail.svg"
          alt="Ícono de correo"
        >
      </a>
      <a
        href="https://instagram.com/zirkular.chile"
        rel="noopener"
        target="_blank"
        aria-label="Ir al perfil de Instagram de Zirkular"
      >
        <img
          src="@/assets/icons/instagram.svg"
          alt="Ícono de Instagram"
        >
      </a>
      <a
        href="https://facebook.com/zirkular.chile"
        rel="noopener"
        target="_blank"
        aria-label="Ir al perfil de Facebook de Zirkular"
      >
        <img
          src="@/assets/icons/facebook.svg"
          alt="Ícono de Facebook"
        >
      </a>
      <!-- <a href="">
        <img src="@/assets/icons/linkedin.svg">
      </a>
      <a href="">
        <img src="@/assets/icons/tiktok.svg">
      </a> -->
    </div>
  </div>
</template>
