<script setup lang="ts">
import { chatMessagesApi } from '@/api/chat-messages';
import { useActionCable } from '@/hooks/actioncable';
import { convertKeys } from '@/utils/case-converter';
import { format } from 'date-fns';
import { groupBy, sortBy } from 'lodash';
import { computed, inject, ref } from 'vue';

interface Props {
  productBooking: ProductBooking,
  chatMessages: ChatMessage[],
}

const props = defineProps<Props>();

const user = inject<User>('user');

const userType = ref(user?.clientAccountId === props.productBooking.clientAccount.id ? 'client' : 'partner');
const recipient = ref(userType.value === 'client' ?
  props.productBooking.partnerAccount : props.productBooking.clientAccount);

const message = ref();

function sendMessage() {
  if (!!message.value) {
    chatMessagesApi.create({
      chatId: props.productBooking.chatId,
      userId: user?.id,
      content: message.value,
    });
    message.value = '';
  }
}

const newMessages = ref<ChatMessage[]>([]);

useActionCable(
  { channel: 'ChatChannel', id: props.productBooking.chatId },
  {
    received(eventNewResource:Record<string, unknown>) {
      const newMessage = convertKeys(eventNewResource, 'camelize');
      newMessages.value.push(newMessage as unknown as ChatMessage);
    },
  },
);

const sortedMessages = computed(() => {
  let allMessages = newMessages.value;
  if (props.chatMessages) {
    allMessages = allMessages.concat(props.chatMessages);
  }

  return groupBy(sortBy(allMessages, 'createdAt'), (msg) => format(new Date(msg.createdAt), 'yyyy-MM-dd'));
});

function handleBack() {
  window.history.back();
}
</script>
<template>
  <div
    class="sticky top-0 z-10 flex w-full shrink-0 flex-col border-b border-z-gray-100 bg-white px-6 py-5"
  >
    <div class="flex items-center justify-between">
      <div class="flex shrink-0 space-x-2">
        <button @click="handleBack">
          <img src="@/assets/icons/chevron-left-alt.svg">
        </button>
        <img
          :src="recipient.pictureUrl"
          class="h-6 rounded-full"
        >
        <p class="line-clamp-1 font-medium text-z-gray-900">
          {{ recipient.firstName }}
        </p>
      </div>
      <p class="line-clamp-1 text-sm font-light text-z-gray-500">
        {{ productBooking.product.name }}
      </p>
    </div>
    <p class="mt-1 text-end text-sm font-light text-z-gray-500">
      {{ format(new Date(productBooking.startDate), "d 'de' MMM") }}
      -
      {{ format(new Date(productBooking.endDate), "d 'de' MMM") }}
    </p>
  </div>
  <div class="min-h-[calc(100vh-88px-70px)] flex-col overflow-y-scroll bg-white px-6">
    <div
      v-for="group, key in sortedMessages"
      :key="key"
      class="mt-4 flex flex-col"
    >
      <p class="mb-4 w-full text-center text-xs">
        {{ format(new Date(key), "d 'de' MMM") }}
      </p>
      <div class="flex w-full flex-col space-y-1">
        <div
          v-for="msg in group"
          :key="msg.id"
          class="w-64 rounded-lg p-3 text-sm"
          :class="msg.userId === recipient.userId ?
            'bg-z-gray-100 text-z-gray-900 self-start' :
            'bg-z-gray-900 text-z-gray-100 self-end'"
        >
          {{ msg.content }}
        </div>
      </div>
    </div>
  </div>
  <div class="fixed bottom-0 h-[70px] w-full border-t border-z-gray-100 bg-white px-6 py-3">
    <div class="flex h-full justify-between rounded-lg border border-z-gray-100 px-3 py-2">
      <input
        v-model="message"
        class="h-full text-sm text-z-gray-800 outline-none placeholder:text-sm placeholder:text-z-gray-500"
        placeholder="Escribe un mensaje"
        @keydown.enter.stop.prevent="sendMessage"
      >
      <button
        class="flex h-7 w-7 items-center justify-center rounded-full bg-z-turquoise-600"
        @click="sendMessage"
      >
        <img
          src="@/assets/icons/send.svg"
          class="h-4 w-4"
        >
      </button>
    </div>
  </div>
</template>
