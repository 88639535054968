<script setup lang="ts">
import { computed, inject, onMounted, ref } from 'vue';
import { debounce, orderBy } from 'lodash';
import { useMutation } from '@tanstack/vue-query';
import { productsApi } from '@/api/products';
import { Popover, PopoverPanel } from '@headlessui/vue';
import * as categoriesApi from '@/api/categories';
import { useStore } from '@/store/store';
import productCard from './product-card.vue';

const searchTerms = ref('');

const searchResults = ref<Product[]>();
const showSearchResults = computed(() => !!searchTerms.value);
const loading = ref(false);

const user = inject<User>('user');

const store = useStore();

const categories = ref<Category[]>();

const { mutate: searchProducts } = useMutation({
  mutationFn: () => productsApi.search(
    searchTerms.value,
    store.userLocation?.latitude,
    store.userLocation?.longitude,
  ) as Promise<Product[]>,
  onSuccess: (data) => {
    searchResults.value = data;
    loading.value = false;
  },
});

// eslint-disable-next-line no-magic-numbers
const debouncedSearch = debounce(searchProducts, 500);

function handleSearch() {
  loading.value = true;
  debouncedSearch();
}

function clearSearch() {
  searchTerms.value = '';
}

onMounted(() => {
  categoriesApi.index().then((response) => {
    categories.value = orderBy(response, 'position');
  });
});
</script>
<template>
  <div class="flex h-8 w-full items-center space-x-2 rounded-full border border-z-gray-100 px-3 py-2 md:h-10 md:max-w-md md:bg-z-gray-25">
    <img
      src="@/assets/icons/search.svg"
      class="h-4 w-4"
      alt="Ícono de lupa"
    >
    <input
      v-model="searchTerms"
      name="search"
      class="h-6 w-full text-xs outline-none placeholder:text-z-gray-500 md:bg-z-gray-25"
      placeholder="Estoy buscando..."
      @input="handleSearch()"
    >
    <button
      aria-label="Limpiar búsqueda"
      @click="clearSearch"
    >
      <img
        v-if="searchTerms"
        src="@/assets/icons/close.svg"
        alt="Ícono de cruz"
      >
    </button>
  </div>
  <Popover v-if="showSearchResults">
    <PopoverPanel
      static
      class="absolute left-0 w-[100vw] overflow-y-scroll bg-white md:left-[calc(12%+93px+32px)] md:h-96 md:max-w-md md:rounded md:border md:border-z-gray-100 md:shadow-md"
      :class="user?.partnerAccountId ?
        'top-[64px] h-[calc(100vh-64px-64px)]' : 'top-[104px] h-[calc(100vh-104px-64px)]'"
    >
      <div
        v-if="loading"
        class="flex h-32 items-center justify-center px-6"
      >
        <img
          src="@/assets/icons/loading.svg"
          class="w-10 animate-spin-slow"
        >
      </div>
      <div
        v-else-if="searchResults?.length == 0"
        class="flex flex-col px-6"
      >
        <!-- <div class="py-3">
          <div class="flex flex-col gap-4 rounded-lg bg-z-yellow-50 p-4">
            <p class="text-center text-xs">
              Si no encontraste lo que buscabas puedes agregar una solicitud aquí:
            </p>
            <a
              class="w-full rounded bg-z-turquoise-600 py-1.5 text-center text-sm text-white"
              href="/necesidades_clientes/crear"
            >Agregar solicitud</a>
          </div>
        </div> -->
        <p class="py-3 text-center text-sm">
          Tu búsqueda no arrojó ningún resultado, pero puedes revisar nuestras categorías:
        </p>
        <div class="mb-12 mt-4 grid grid-cols-3 gap-x-4 gap-y-6">
          <div
            v-for="category in categories"
            :key="category.id"
            class="flex flex-col items-center space-y-2"
          >
            <img
              :src="category.picture.webpSm.url"
              class="h-20 w-20 rounded-full object-cover"
            >
            <p class="text-center text-xs">
              {{ category.name }}
            </p>
          </div>
        </div>
      </div>
      <div
        v-else
      >
        <div class="border-b border-z-gray-100 px-6 pb-4 pt-3">
          <p class="text-lg font-medium italic text-z-turquoise-600">
            {{ searchTerms }}
          </p>
          <p class="text-z-gray-800">
            Se encontraron <span class="font-semibold">{{ searchResults?.length ?? 0 }} resultados:</span>
          </p>
        </div>
        <div class="grid grid-cols-2 gap-4 px-6 py-4">
          <product-card
            v-for="product in searchResults"
            :key="product.id"
            :product="product"
          />
        </div>
      </div>
    </PopoverPanel>
  </Popover>
</template>
